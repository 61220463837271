import {
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  GithubAuthProvider,
  GoogleAuthProvider,
  onAuthStateChanged,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithRedirect,
  TwitterAuthProvider,
} from "firebase/auth";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { FirebaseContext } from "../firebase";
import api from "../helpers/api";

interface ErrorObject {
  code: string;
  message: string;
}
interface AuthContextType {
  user: any;
  token: string;
  loading: boolean;
  loadingInitial: boolean;
  subscription: any;
  signUpWithEmail: (
    email: string,
    password: string
  ) => Promise<ErrorObject | void>;
  signInWithEmail: (
    email: string,
    password: string
  ) => Promise<ErrorObject | void>;
  signInWithAuthProvider: (
    authProvider: TwitterAuthProvider | GoogleAuthProvider | GithubAuthProvider
  ) => void;
  signout: (callback?: VoidFunction) => void;
}

let AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const firebase = useContext(FirebaseContext);
  const auth = firebase!.auth;
  let [user, setUser] = React.useState<any>(
    auth.currentUser ||
      JSON.parse(localStorage.getItem("currentUser") || "null")
  );
  let [token, setToken] = React.useState<string>(
    localStorage.getItem("token") ?? ""
  );
  const [loadingInitial, setLoadingInitial] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [subscription, setSubscription] = useState<any>();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoadingInitial(false);
      if (user) {
        localStorage.setItem("currentUser", JSON.stringify(user));
        setLoading(true);
        user
          .getIdToken()
          .then((tokenRes) => {
            localStorage.setItem("token", tokenRes);
            setToken(tokenRes);
            api.updateClient(tokenRes);
            api
              .getCustomerSubscriptions()
              .then((subscriptions) => {
                if (subscriptions.data.subscriptions.length > 0) {
                  for (const subscription of subscriptions.data.subscriptions) {
                    if (
                      subscription.status === "active" ||
                      subscription.status === "trialing"
                    ) {
                      setSubscription(subscription);
                      return;
                    }
                  }
                  setSubscription(undefined);
                } else {
                  setSubscription(undefined);
                }
              })
              .catch((err) => {
                console.error(err);
              })
              .finally(() => {
                setLoading(false);
              });
          })
          .catch((err) => {
            console.error("Error while getting JWT from Firebase -", err);
          });
      }
    });
  }, []);

  let signUpWithEmail = async (
    email: string,
    password: string
  ): Promise<void | ErrorObject> => {
    return new Promise((resolve, reject) => {
      setPersistence(auth, browserLocalPersistence)
        .then(() => {
          createUserWithEmailAndPassword(auth, email, password)
            .then(() => {
              resolve();
            })
            .catch((err) => {
              reject({ code: err.code, message: err.message });
            });
        })
        .catch((err) => {
          reject({ code: err.code, message: err.message });
        });
    });
  };

  let signInWithEmail = async (
    email: string,
    password: string
  ): Promise<void | ErrorObject> => {
    return new Promise((resolve, reject) => {
      setPersistence(auth, browserLocalPersistence)
        .then(() => {
          signInWithEmailAndPassword(auth, email, password)
            .then(() => {
              resolve();
            })
            .catch((err) => {
              reject({ code: err.code, message: err.message });
            });
        })
        .catch((err) => {
          reject({ code: err.code, message: err.message });
        });
    });
  };

  let signInWithAuthProvider = async (
    authProvider: TwitterAuthProvider | GoogleAuthProvider | GithubAuthProvider
  ) => {
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        signInWithRedirect(auth, authProvider);
      })
      .catch((err) => {
        console.error({ code: err.code, message: err.message });
      });
  };

  let signout = (callback?: VoidFunction) => {
    return auth.signOut().then(() => {
      setUser(null);
      setToken("");
      localStorage.removeItem("token");
      localStorage.removeItem("currentUser");
      if (callback) {
        callback();
      }
    });
  };

  const memoedValue = useMemo(
    () => ({
      user,
      token,
      loading,
      loadingInitial,
      subscription,
      signUpWithEmail,
      signInWithEmail,
      signInWithAuthProvider,
      signout,
    }),
    [user, loading]
  );

  return (
    <AuthContext.Provider value={memoedValue}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return React.useContext(AuthContext);
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  const location = useLocation();
  let auth = useAuth();

  if (!auth.user) {
    return (
      <Navigate to="/signin" replace state={{ from: location.pathname }} />
    );
  }

  return children;
}

export function RequireSubscription({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const [returnComponent, setReturnComponent] = useState<JSX.Element>(children);

  useEffect(() => {
    if (auth.user && !auth.loading) {
      api.updateClient(auth.token);
      api
        .getCustomerSubscriptions()
        .then((subscriptions) => {
          if (subscriptions.data.subscriptions.length > 0) {
            for (const subscription of subscriptions.data.subscriptions) {
              if (
                subscription.status === "active" ||
                subscription.status === "trialing"
              ) {
                setReturnComponent(children);
                return;
              }
              setReturnComponent(<Navigate to="/choose-subscription" />);
            }
          } else {
            setReturnComponent(<Navigate to="/choose-subscription" />);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [auth.loading]);

  return returnComponent;
}
