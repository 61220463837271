import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ChooseSubscription from "./pages/ChooseSubscription/ChooseSubscription";
import Dashboard from "./pages/Dashboard/Dashboard";
import ManageProject from "./pages/ManageProject/ManageProject";
import Settings from "./pages/Settings/Settings";
import SignIn from "./pages/SignIn/SignIn";
import SignOut from "./pages/SignOut/SignOut";
import SignUp from "./pages/SignUp/SignUp";
import { AuthProvider, RequireAuth } from "./utils/auth/auth";

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signout" element={<SignOut />} />
          <Route
            path="/choose-subscription"
            element={
              <RequireAuth>
                <ChooseSubscription />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route
            path="/projects/:projectSlug"
            element={
              <RequireAuth>
                <ManageProject />
              </RequireAuth>
            }
          />
          <Route
            path="/settings/*"
            element={
              <RequireAuth>
                <Settings />
              </RequireAuth>
            }
          />
          <Route path="/" element={<Navigate to="/signin" />} />
        </Routes>
        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          hideProgressBar
        />
      </AuthProvider>
    </Router>
  );
}

export default App;
