import { CheckIcon } from "@heroicons/react/solid";
import { Alert, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../utils/helpers/api";
import { classNames } from "../Dashboard/Dashboard";

export default function ChooseSubscription() {
  const [billingType, setBillingType] = useState<"monthly" | "yearly">(
    "monthly"
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(false);
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  const tiers = [
    {
      name: "Free",
      href: "#",
      priceMonthly: 0,
      priceYearly: 0,
      description: "For just getting started",
      includedFeatures: [
        "Unlimited sites (markbase.xyz subdomain)",
        "Custom domain",
        "Sync once an hour",
        "Changes go live in minutes!",
      ],
      onClickMonthly: () => navigate(-1),
      onClickYearly: () => navigate(-1),
    },
    {
      name: "Hobby",
      href: "#",
      priceMonthly: 5,
      priceYearly: 4,
      exPriceMonthly: 9,
      exPriceYearly: 8,
      description: "For those who need just one site",
      includedFeatures: [
        "Everything in the Free plan",
        "1 site with a custom domain (or unlimited markbase.xyz sites)",
        "Sync once a minute",
        "Fast, full text search",
        "Custom CSS",
        "Custom JavaScript",
      ],
      onClickMonthly: () =>
        goToStripeCheckout(
          process.env.REACT_APP_STRIPE_HOBBY_MONTHLY_SUBSCRIPTION_PRICE_ID ?? ""
        ),
      onClickYearly: () =>
        goToStripeCheckout(
          process.env.REACT_APP_STRIPE_HOBBY_YEARLY_SUBSCRIPTION_PRICE_ID ?? ""
        ),
    },
    {
      name: "Unlimited",
      href: "#",
      priceMonthly: 15,
      priceYearly: 12,
      exPriceMonthly: 29,
      exPriceYearly: 25,
      description: "For bloggers & gardeners",
      includedFeatures: [
        "Everything in the Free & Hobby plans",
        "Unlimited sites with a custom domain",
      ],
      onClickMonthly: () =>
        goToStripeCheckout(
          process.env
            .REACT_APP_STRIPE_UNLIMITED_MONTHLY_SUBSCRIPTION_PRICE_ID ?? ""
        ),
      onClickYearly: () =>
        goToStripeCheckout(
          process.env.REACT_APP_STRIPE_UNLIMITED_YEARLY_SUBSCRIPTION_PRICE_ID ??
            ""
        ),
    },
  ];

  const goToStripeCheckout = (priceId: string) => {
    setLoading(true);
    api
      .getStripeCheckoutSessionUrl(priceId)
      .then((res) => {
        if (res.data.url) {
          window.location.href = res.data.url;
        } else {
          // Throw error
          setError(true);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(
          "Error occurred while trying to get stripe checkout session URL -",
          err
        );
        // Throw error
        setError(true);
      });
  };

  const closeSnackbar = () => {
    setError(false);
  };

  return (
    <div className="bg-white h-full">
      <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:flex-col sm:align-center">
          <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">
            Subscribe to Markbase
          </h1>
          <p className="mt-5 text-xl text-gray-500 sm:text-center">
            Get started for free, then upgrade to unlock additional features
          </p>
          <div className="relative self-center mt-6 bg-gray-100 rounded-lg p-0.5 flex sm:mt-8">
            <button
              disabled={loading}
              type="button"
              onClick={() => setBillingType("monthly")}
              className={classNames(
                billingType === "monthly"
                  ? "relative w-1/2 bg-white border-gray-200 rounded-md shadow-sm py-2 text-sm font-medium text-gray-900 whitespace-nowrap sm:w-auto sm:px-8"
                  : "relative w-1/2 border border-transparent rounded-md py-2 text-sm font-medium text-gray-700 whitespace-nowrap sm:w-auto sm:px-8",
                "disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200"
              )}
            >
              Monthly billing
            </button>
            <button
              disabled={loading}
              type="button"
              onClick={() => setBillingType("yearly")}
              className={classNames(
                billingType === "yearly"
                  ? "ml-0.5 relative w-1/2 bg-white border-gray-200 rounded-md shadow-sm py-2 text-sm font-medium text-gray-900 whitespace-nowrap sm:w-auto sm:px-8"
                  : "ml-0.5 relative w-1/2 border border-transparent rounded-md py-2 text-sm font-medium text-gray-700 whitespace-nowrap sm:w-auto sm:px-8",
                "disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200"
              )}
            >
              Yearly billing
            </button>
          </div>
        </div>
        <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-1 md:grid-cols-3 sm:gap-6 lg:max-w-4xl mx-auto">
          {tiers.map((tier) => (
            <div
              key={tier.name}
              className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200"
            >
              <div className="p-6">
                <h2 className="text-lg leading-6 font-medium text-gray-900">
                  {tier.name}
                </h2>
                <p className="mt-4 text-sm text-gray-500">{tier.description}</p>
                <p className="mt-8">
                  {/* {tier.exPriceMonthly && tier.exPriceYearly && (
                    <span className="line-through text-4xl font-extrabold text-gray-900 mr-2">
                      $
                      {billingType === "monthly"
                        ? tier.exPriceMonthly
                        : tier.exPriceYearly}
                    </span>
                  )} */}
                  <span className="text-4xl font-extrabold text-gray-900">
                    $
                    {billingType === "monthly"
                      ? tier.priceMonthly
                      : tier.priceYearly}
                  </span>{" "}
                  <span className="text-base font-medium text-gray-500">
                    /mo
                  </span>
                </p>
                <button
                  disabled={loading}
                  onClick={() =>
                    billingType === "monthly"
                      ? tier.onClickMonthly()
                      : tier.onClickYearly()
                  }
                  className="cursor-pointer mt-8 pmt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                >
                  {tier.name !== "Free"
                    ? "Subscribe to " + tier.name
                    : "Stay Free"}
                </button>
              </div>
              <div className="pt-6 pb-8 px-6">
                <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
                  What's included
                </h3>
                <ul className="mt-6 space-y-4">
                  {tier.includedFeatures.map((feature) => (
                    <li key={feature} className="flex space-x-3">
                      <span className="text-sm text-gray-500">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="w-100 mx-auto text-center">
        <button
          onClick={goBack}
          type="button"
          className="inline-flex items-center m-8 px-4 py-2 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 mr-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            />
          </svg>
          Back
        </button>
      </div>
      <Snackbar open={error} onClose={closeSnackbar} autoHideDuration={2000}>
        <Alert severity="error" sx={{ width: "100%" }}>
          An error occurred while redirecting to Stripe checkout - please try
          again or contact support
        </Alert>
      </Snackbar>
    </div>
  );
}