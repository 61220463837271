import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../utils/auth/auth";
import api from "../../../utils/helpers/api";

export default function SubscriptionSettings() {
  const auth = useAuth();
  const [subscription, setSubscription] = useState<any>();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const goToManageSubscriptionPortal = () => {
    setLoading(true);
    api
      .getStripePortalSessionUrl()
      .then((res) => {
        window.location.assign(res.data.url);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const goToChooseSubscriptionPage = () => {
    navigate("/choose-subscription");
  };

  useEffect(() => {
    if (auth.user && !auth.loading) {
      setSubscription(auth.subscription);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.loading]);

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
      {/* Plan */}
      <section aria-labelledby="plan-heading">
        <form action="#" method="POST">
          <div className="shadow sm:rounded-md sm:overflow-hidden mx-2 sm:mx-0">
            <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
              <div>
                <h2
                  id="plan-heading"
                  className="text-lg leading-6 font-medium text-gray-900"
                >
                  Plan
                </h2>
              </div>
              <div>
                {loading ? (
                  <>
                    <div className="font-bold">Loading...</div>
                    <div className="text-sm w-1/2 pt-2 text-gray-500">
                      If this page has been loading for a while, check for any
                      Adblockers/extensions installed that could block essential
                      scripts from running
                    </div>
                  </>
                ) : subscription ? (
                  <>
                    <div>
                      You're currently on the{" "}
                      <strong>
                        ${subscription.plan.amount / 100}/
                        {subscription.plan.interval}
                      </strong>{" "}
                      plan
                    </div>
                    <div>
                      {subscription.cancel_at ? (
                        <div>
                          Your subscription will be cancelled on{" "}
                          {new Date(
                            subscription.cancel_at * 1000
                          ).toDateString()}
                        </div>
                      ) : (
                        <div>
                          Your subscription will be renewed on{" "}
                          {new Date(
                            subscription.current_period_end * 1000
                          ).toDateString()}
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <div>
                    You're currently on the <strong>free plan</strong>
                  </div>
                )}
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-left sm:px-6">
              {subscription ? (
                <button
                  disabled={loading}
                  onClick={goToManageSubscriptionPortal}
                  className="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                >
                  Manage Subscription
                </button>
              ) : (
                <button
                  disabled={loading}
                  onClick={goToChooseSubscriptionPage}
                  className="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                >
                  Upgrade
                </button>
              )}
            </div>
          </div>
        </form>
      </section>
    </div>
  );
}
