import { DuplicateIcon } from "@heroicons/react/solid";
import { toast } from "react-toastify";

interface CopyFieldProps {
  labelText?: string;
  leadingIcon?: JSX.Element;
  value: string;
}

export default function CopyField(props: CopyFieldProps) {
  const copyTextToClipboard = () => {
    navigator.clipboard.writeText(props.value);
    toast.success("Copied to clipboard!");
  };

  return (
    <div>
      {props.labelText && (
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700"
        >
          {props.labelText}
        </label>
      )}

      <div className="mt-1 flex rounded-md shadow-sm">
        <div className="relative flex items-stretch flex-grow focus-within:z-10">
          {props.leadingIcon && (
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              {props.leadingIcon}
              {/* <UsersIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /> */}
            </div>
          )}

          <input
            type="text"
            name="text"
            id="text"
            disabled
            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300"
            placeholder=""
            value={props.value}
          />
        </div>
        <button
          type="button"
          className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          onClick={copyTextToClipboard}
        >
          <DuplicateIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
}
