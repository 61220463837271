import axios, { AxiosInstance, AxiosRequestHeaders } from "axios";

class Api {
  token: string | null;
  client: AxiosInstance;
  apiBaseUrl: string;

  constructor() {
    this.apiBaseUrl = process.env.REACT_APP_API_BASE_URL ?? "";
    this.token = localStorage.getItem("token");

    const headers: AxiosRequestHeaders = {
      Accept: "application/json",
    };

    if (this.token) {
      headers.Authorization = `Bearer ${this.token}`;
    }

    this.client = axios.create({
      baseURL: this.apiBaseUrl,
      timeout: 31000,
      headers: headers,
    });
  }

  updateClient = (newToken: string) => {
    this.token = newToken;

    // Update API
    const headers: AxiosRequestHeaders = {
      Accept: "application/json",
    };

    if (this.token) {
      headers.Authorization = `Bearer ${this.token}`;
    }

    this.client = axios.create({
      baseURL: this.apiBaseUrl,
      timeout: 31000,
      headers: headers,
    });

    return this.client;
  };

  // Endpoints
  // User
  createUserIfDoesntExist = () => {
    return this.client.post("/user");
  };

  setUserLastSentFeedback = () => {
    return this.client.post("/user/feedback");
  };

  completeUserOnboarding = () => {
    return this.client.post("/user/onboarding");
  };

  getMyInfo = () => {
    return this.client.get("/user/me");
  };

  // Other
  getObsidianToken = () => {
    return this.client.get("/token/obsidian");
  };

  verifyObsidianToken = () => {
    return this.client.get("/token/obsidian/verify");
  };

  listProjectsForUser = () => {
    return this.client.get("/projects/user");
  };

  getProjectBySlug = (slug: string) => {
    return this.client.get("/projects/slug/" + slug);
  };

  createProjectForUser = (
    slug: string,
    name: string,
    folderToShare: string,
    projectPublic: boolean
  ) => {
    return this.client.post("/projects/user", {
      slug,
      name,
      folderToShare,
      public: projectPublic,
    });
  };

  uploadCustomCssToProjectForUser = (slug: string, customCss: string) => {
    return this.client.post("/projects/user/custom-css", {
      slug,
      customCss,
    });
  };

  uploadCustomHeadJsToProjectForUser = (slug: string, customHeadJs: string) => {
    return this.client.post("/projects/user/custom-head-js", {
      slug,
      customHeadJs,
    });
  };

  uploadCustomBodyJsToProjectForUser = (slug: string, customBodyJs: string) => {
    return this.client.post("/projects/user/custom-body-js", {
      slug,
      customBodyJs,
    });
  };

  updateProjectCustomDomain = (slug: string, customDomain: string) => {
    return this.client.post("/projects/user/custom-domain", {
      slug,
      customDomain,
    });
  };

  deleteProjectById = (projectId: string) => {
    return this.client.delete("/projects/id/" + projectId);
  };

  // Stripe
  getStripeCheckoutSessionUrl = (priceId: string) => {
    return this.client.get("/stripe/checkout", {
      params: {
        priceId: priceId,
      },
    });
  };

  getStripePortalSessionUrl = () => {
    return this.client.get("/stripe/portal");
  };

  getCustomerSubscriptions = () => {
    return this.client.get("/stripe/subscriptions");
  };
}

export default new Api();
