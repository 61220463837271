import "@fontsource/lato/300.css";
import "@fontsource/lato/400.css";
import "@fontsource/lato/700.css";
import ReactDOM from "react-dom";
import App from "./App";
import "./assets/styles/output.css";
import Firebase, { FirebaseContext } from "./utils/firebase";

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <App />
  </FirebaseContext.Provider>,
  document.getElementById("root")
);
