import { onAuthStateChanged } from "firebase/auth";
import { useContext, useEffect, useState } from "react";
import { useAuth } from "../../../utils/auth/auth";
import { FirebaseContext } from "../../../utils/firebase";

export default function AccountSettings() {
  const firebase = useContext(FirebaseContext);
  const auth = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    onAuthStateChanged(firebase!.auth, (user) => {
      if (user) {
        setLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="divide-y divide-gray-200 lg:col-span-9">
      <div className="py-6 px-4 sm:p-6 lg:pb-8">
        <div>
          <h2 className="text-lg leading-6 font-medium text-gray-900 mb-4">
            Account details
          </h2>
          <div>
            {loading ? (
              <>
                <div className="font-bold">Loading...</div>
                <div className="text-sm w-1/2 pt-2 text-gray-500">
                  If this page has been loading for a while, check for any
                  Adblockers/extensions installed that could block essential
                  scripts from running
                </div>
              </>
            ) : (
              <div>
                <div className="mb-4">
                  {auth.user?.photoURL ? (
                    <img
                      className="h-16 w-16 rounded-full"
                      src={auth.user.photoURL}
                      alt=""
                    />
                  ) : (
                    <span className="inline-block h-16 w-16 rounded-full overflow-hidden bg-gray-100">
                      <svg
                        className="h-full w-full text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    </span>
                  )}
                </div>
                <div>
                  <strong>Email: </strong>
                  {auth.user.email}
                </div>
                <div>
                  <strong>Joined: </strong>
                  {new Date(
                    parseInt(auth.user.metadata.createdAt)
                  ).toDateString()}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
