import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import MarkbaseFavicon from "../../assets/img/markbase-favicon.png";

interface OnboardingPopupProps {
  open: boolean;
  setOpen: (newOpenValue: boolean) => void;
}

export default function OnboardingPopup(props: OnboardingPopupProps) {
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-xl h-xl sm:w-full sm:p-6">
                <div>
                  <img
                    src={MarkbaseFavicon}
                    className="mx-auto h-12 w-12 text-green-600 rounded-full"
                    aria-hidden="true"
                  />
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-bold text-gray-900"
                    >
                      Welcome to Markbase!
                    </Dialog.Title>
                  </div>
                  <div>
                    <iframe
                      src="https://tally.so/embed/3EkeZA?alignLeft=1&hideTitle=1&transparentBackground=1"
                      width="100%"
                      height="500"
                      frameBorder="0"
                      marginHeight={0}
                      marginWidth={0}
                      title="Onboarding form - Markbase (Minimal)"
                    ></iframe>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
