import { MapIcon, QuestionMarkCircleIcon } from "@heroicons/react/outline";
import { CheckIcon, KeyIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DefaultProfilePic from "../../assets/img/default-profile-pic.jpg";
import CopyField from "../../components/CopyField/CopyField";
import FeedbackPopup from "../../components/FeedbackPopup/FeedbackPopup";
import NavBar from "../../components/NavBar/NavBar";
import OnboardingPopup from "../../components/OnboardingPopup/OnboardingPopup";
import { useAuth } from "../../utils/auth/auth";
import api from "../../utils/helpers/api";

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Dashboard() {
  const auth = useAuth();
  const [user, setUser] = useState({
    name: "",
    email: "tom@example.com",
    imageUrl: DefaultProfilePic,
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [obsidianUserToken, setObsidianUserToken] = useState<string>("");
  const [userProjects, setUserProjects] = useState<any[]>([]);
  const [displayFeedbackPopup, setDisplayFeedbackPopup] =
    useState<boolean>(false);
  const [displayOnboardingPopup, setDisplayOnboardingPopup] =
    useState<boolean>(false);

  const steps = [
    {
      name: "Install the Markbase for Obsidian plugin",
      description:
        "You can find it in the Obsidian community marketplace (or click here)",
      href: "https://obsidian.md/plugins?id=obsidian-markbase",
      status: "current",
    },
    {
      name: "Copy your unique user token (below) into the plugin settings section",
      description: "This is how Markbase identifies you inside the plugin",
      href: "#",
      status: "upcoming",
    },
    {
      name: "In the plugin settings, choose the folder you want to upload/share online",
      description:
        "All of the files/folders inside this folder (except for the ones you exclude) will be made public",
      href: "#",
      status: "upcoming",
    },
    {
      name: "You're done! Your folder should be live",
      description:
        "It takes a few minutes to deploy but once done, you can share your folder with the world!",
      href: "#",
      status: "upcoming",
    },
  ];

  useEffect(() => {
    if (auth.user && !auth.loading) {
      setUser({
        name: auth.user.displayName ?? "",
        email: auth.user.email ?? "unknown-email@example.com",
        imageUrl: auth.user.photoURL ?? DefaultProfilePic,
      });

      initializeDashboard();
    }
  }, [auth.loading]);

  const initializeDashboard = async () => {
    api.updateClient(auth.token);
    try {
      const listProjectsRes = await api.listProjectsForUser();

      if ((listProjectsRes.data.projects as any[]).length > 0) {
        setUserProjects(listProjectsRes.data.projects);
      }

      const obsidianTokenRes = await api.getObsidianToken();
      if (obsidianTokenRes.data.token) {
        setObsidianUserToken(obsidianTokenRes.data.token);
      }
    } catch (error) {
      console.error(
        "Error listing projects or getting obsidian token for user - ",
        error
      );
    } finally {
      setLoading(false);
    }

    try {
      const user = await api.getMyInfo();
      setUser(user.data);
      try {
        if (
          Object.prototype.hasOwnProperty.call(user.data, "onboardingComplete")
        ) {
          if (!user.data.onboardingComplete) {
            setDisplayOnboardingPopup(true);
            await api.completeUserOnboarding();
          }
        }
      } catch (error) {
        setDisplayOnboardingPopup(false);
      }

      try {
        if (
          Object.prototype.hasOwnProperty.call(
            user.data,
            "feedbackPopupLastSent"
          )
        ) {
          const sevenDaysAgoDate = new Date();
          const tempDate = sevenDaysAgoDate.getDate() - 7;
          sevenDaysAgoDate.setDate(tempDate);

          if (!user.data.feedbackPopupLastSent) {
            // Reset the feedback popup timer
            await api.setUserLastSentFeedback();
          } else if (
            sevenDaysAgoDate > new Date(user.data.feedbackPopupLastSent)
          ) {
            // Display the feedback popup
            setDisplayFeedbackPopup(true);
            // Reset the feedback popup timer
            await api.setUserLastSentFeedback();
          }
        }
      } catch (error) {
        setDisplayFeedbackPopup(false);
      }
    } catch (error) {
      console.error("Error in getMyInfo -", error);
    }
  };

  return (
    <>
      <div className="min-h-full">
        <NavBar pageName="Dashboard" />

        <main className="-mt-32">
          <div className="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
            {/* Replace with your content */}
            <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
              {loading ? (
                <>
                  <div className="font-bold">Loading...</div>
                  <div className="text-sm w-1/2 pt-2 text-gray-500">
                    If this page has been loading for a while, check for any
                    Adblockers/extensions installed that could block essential
                    scripts from running
                  </div>
                </>
              ) : userProjects.length > 0 ? (
                <>
                  <h2 className="text-2xl font-bold text-black">
                    Your Projects
                  </h2>
                  <div className="mt-2">
                    Resync your projects from the Markbase for Obsidian plugin's
                    settings
                  </div>
                  <div className="my-8 flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                >
                                  Name
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                                >
                                  URL
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Last Updated
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Visibility
                                </th>
                                <th
                                  scope="col"
                                  className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                                >
                                  <span className="sr-only">Edit</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                              {userProjects.map((project, i) => (
                                <tr key={i}>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    {project.name}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {project.publishedUrl}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {new Date(
                                      project.metadata.updatedAt
                                    ).toDateString()}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {project.public ? (
                                      <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                        Public
                                      </span>
                                    ) : (
                                      <span className="inline-flex rounded-full bg-indigo-100 px-2 text-xs font-semibold leading-5 text-indigo-800">
                                        Private
                                      </span>
                                    )}
                                  </td>
                                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                    <Link to={`/projects/${project.slug}`}>
                                      <div className="cursor-pointer text-indigo-600 hover:text-indigo-900">
                                        Manage
                                      </div>
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h2 className="text-2xl font-bold text-black">Your Token</h2>
                  <div className="mt-2">
                    Take your unique user token below and copy it into the
                    Markbase for Obsidian plugin settings section
                  </div>
                  <div className="mt-4 flex flex-col">
                    <CopyField
                      value={obsidianUserToken}
                      leadingIcon={
                        <KeyIcon className="h-5 w-5 text-gray-400" />
                      }
                    />
                  </div>
                  {!auth.subscription && (
                    <div className="bg-gray-800 rounded-md p-4 mt-6 text-white text-sm">
                      <h2 className="text-xl font-bold">⚡ Upgrade</h2>
                      <div className="mt-2">
                        <Link to={"/choose-subscription"}>
                          <span className="text-indigo-400 hover:text-indigo-600">
                            Upgrade to a Markbase paid plan
                          </span>
                        </Link>{" "}
                        to gain access to:
                      </div>
                      <ul className="mt-2">
                        <li>✅ Custom domains</li>
                        <li>✅ Fast, full text search</li>
                        <li>✅ Custom CSS</li>
                        <li>✅ Custom JavaScript</li>
                      </ul>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <h2 className="text-2xl font-bold text-black">
                    Welcome to Markbase
                    {user.name !== "" ?? ", " + user.name.split(" ")[0]}! 🎉
                  </h2>
                  <h3 className="text-xl font-semibold text-black mt-2 mb-4">
                    Here's how to get started
                  </h3>
                  <nav aria-label="Progress">
                    <ol role="list" className="overflow-hidden">
                      {steps.map((step, stepIdx) => (
                        <li
                          key={step.name}
                          className={classNames(
                            stepIdx !== steps.length - 1 ? "pb-10" : "",
                            "relative"
                          )}
                        >
                          {step.status === "complete" ? (
                            <>
                              {stepIdx !== steps.length - 1 ? (
                                <div
                                  className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-indigo-600"
                                  aria-hidden="true"
                                />
                              ) : null}
                              <a
                                href={step.href}
                                className="relative flex items-start group"
                              >
                                <span className="h-9 flex items-center">
                                  <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
                                    <CheckIcon
                                      className="w-5 h-5 text-white"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </span>
                                <span className="ml-4 min-w-0 flex flex-col">
                                  <span className="text-xs font-semibold tracking-wide uppercase">
                                    {step.name}
                                  </span>
                                  <span className="text-sm text-gray-500">
                                    {step.description}
                                  </span>
                                </span>
                              </a>
                            </>
                          ) : step.status === "current" ? (
                            <>
                              {stepIdx !== steps.length - 1 ? (
                                <div
                                  className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                                  aria-hidden="true"
                                />
                              ) : null}
                              <a
                                href={step.href}
                                className="relative flex items-start group"
                                aria-current="step"
                              >
                                <span
                                  className="h-9 flex items-center"
                                  aria-hidden="true"
                                >
                                  <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-indigo-600 rounded-full">
                                    <span className="h-2.5 w-2.5 bg-indigo-600 rounded-full" />
                                  </span>
                                </span>
                                <span className="ml-4 min-w-0 flex flex-col">
                                  <span className="text-xs font-semibold tracking-wide uppercase text-indigo-600">
                                    {step.name}
                                  </span>
                                  <span className="text-sm text-gray-500">
                                    {step.description}
                                  </span>
                                </span>
                              </a>
                            </>
                          ) : (
                            <>
                              {stepIdx !== steps.length - 1 ? (
                                <div
                                  className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                                  aria-hidden="true"
                                />
                              ) : null}
                              <a
                                href={step.href}
                                className="relative flex items-start group"
                              >
                                <span
                                  className="h-9 flex items-center"
                                  aria-hidden="true"
                                >
                                  <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                                    <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
                                  </span>
                                </span>
                                <span className="ml-4 min-w-0 flex flex-col">
                                  <span className="text-xs font-semibold tracking-wide uppercase text-gray-500">
                                    {step.name}
                                  </span>
                                  <span className="text-sm text-gray-500">
                                    {step.description}
                                  </span>
                                </span>
                              </a>
                            </>
                          )}
                        </li>
                      ))}
                    </ol>
                  </nav>
                  <div className="mt-8 mb-2 w-1/3">
                    <h3 className="text-lg font-semibold mb-2">
                      Your Unique Token
                    </h3>
                    <CopyField
                      value={obsidianUserToken}
                      leadingIcon={
                        <KeyIcon className="h-5 w-5 text-gray-400" />
                      }
                    />
                  </div>
                  <div className="mt-8 mb-2">
                    <h3 className="text-lg font-semibold mb-2">Useful Links</h3>
                    <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                      <li className="col-span-1 bg-slate-50 hover:bg-slate-100 rounded-lg shadow divide-y divide-gray-200 cursor-pointer text-gray-700">
                        <a href="https://trello.com/b/qYaGuz0i/markbase-product-roadmap">
                          <div className="w-full flex items-center justify-between p-6 space-x-6">
                            <div className="flex-1 truncate">
                              <div className="flex items-center space-x-3">
                                <h3 className="text-gray-900 text-md font-medium flex flex-row items-center">
                                  <MapIcon className="h-5 w-5" />
                                  <span className="ml-1">
                                    Public Roadmap/Feedback
                                  </span>
                                </h3>
                              </div>
                              <p className="mt-1 text-gray-500 text-sm whitespace-pre-wrap">
                                Vote on/suggest features you want to see next
                                and see where Markbase is going in future
                              </p>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li
                        // onClick={() => goToPage(result.ref)}
                        className="col-span-1 bg-slate-50 hover:bg-slate-100 rounded-lg shadow divide-y divide-gray-200 cursor-pointer text-gray-700"
                      >
                        <a href="https://markbase.tawk.help/">
                          <div className="w-full flex items-center justify-between p-6 space-x-6">
                            <div className="flex-1 truncate">
                              <div className="flex items-center space-x-3">
                                <h3 className="text-gray-900 text-md font-medium flex flex-row items-center">
                                  <QuestionMarkCircleIcon className="h-5 w-5" />
                                  <span className="ml-1">Help Center</span>
                                </h3>
                              </div>
                              <p className="mt-1 text-gray-500 text-sm whitespace-pre-wrap">
                                Find out how to use Markbase, report bugs and
                                learn about its features
                              </p>
                            </div>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                  {!auth.subscription && (
                    <div className="bg-gray-800 rounded-md p-4 mt-6 text-white text-sm">
                      <h2 className="text-xl font-bold">⚡ Upgrade</h2>
                      <div className="mt-2">
                        <Link to={"/choose-subscription"}>
                          <span className="text-indigo-400 hover:text-indigo-600">
                            Upgrade to a Markbase paid plan
                          </span>
                        </Link>{" "}
                        to gain access to:
                      </div>
                      <ul className="mt-2">
                        <li>✅ Custom domains</li>
                        <li>✅ Fast, full text search</li>
                        <li>✅ Custom CSS</li>
                        <li>✅ Custom JavaScript</li>
                      </ul>
                    </div>
                  )}
                </>
              )}
            </div>
            {/* /End replace */}
          </div>
        </main>
      </div>
      <FeedbackPopup
        open={displayFeedbackPopup}
        setOpen={setDisplayFeedbackPopup}
      />
      <OnboardingPopup
        open={displayOnboardingPopup}
        setOpen={setDisplayOnboardingPopup}
      />
    </>
  );
}
