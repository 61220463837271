import { Transition } from "@headlessui/react";
import { CheckCircleIcon, XIcon } from "@heroicons/react/solid";
import { Fragment, useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import NavBar from "../../components/NavBar/NavBar";
import { useAuth } from "../../utils/auth/auth";
import api from "../../utils/helpers/api";
import { Project } from "../../utils/types";

export default function ManageProject() {
  const navigate = useNavigate();
  const auth = useAuth();
  const params = useParams();
  const [loadingProject, setLoadingProject] = useState<boolean>(true);
  const [showSuccessNotif, setShowSuccessNotif] = useState<boolean>(false);
  const [showErrorNotif, setShowErrorNotif] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [customDomain, setCustomDomain] = useState<string>("");
  const [customCss, setCustomCss] = useState<string>("");
  const [customHeadJs, setCustomHeadJs] = useState<string>("");
  const [customBodyJs, setCustomBodyJs] = useState<string>("");
  const [openDeleteProjectDialog, setOpenDeleteProjectDialog] =
    useState<boolean>(false);
  const [project, setProject] = useState<Project>();

  useEffect(() => {
    if (auth.user && !auth.loading) {
      initializePage();
    }
  }, [auth.loading]);

  const initializePage = async () => {
    try {
      const getProjectRes = await api.getProjectBySlug(
        params.projectSlug ?? ""
      );
      setProject(getProjectRes.data.project);
      setCustomDomain(getProjectRes.data.project.publishedUrl);
      setCustomCss(getProjectRes.data.project.customCss);
      setCustomHeadJs(getProjectRes.data.project.customHeadJs);
      setCustomBodyJs(getProjectRes.data.project.customBodyJs);
    } catch (error) {
      console.error("Error getting project for user - ", error);
    } finally {
      setLoadingProject(false);
    }
  };

  const deleteProject = async () => {
    setOpenDeleteProjectDialog(false);
    setLoading(true);

    try {
      await api.deleteProjectById(project?.id ?? "");
      navigate("/dashboard");
    } catch (error) {
      console.error("Error occurred while trying to delete project -", error);
    }
    setLoading(false);
  };

  const updateCustomDomain = async () => {
    if (auth.subscription) {
      setLoading(true);

      try {
        await api.updateProjectCustomDomain(project?.slug ?? "", customDomain);
        setShowSuccessNotif(true);
      } catch (error) {
        console.error(
          "Error occurred while trying to update custom domain -",
          error
        );
        setShowErrorNotif(true);
      }
      setLoading(false);
    }
  };

  const updateCss = async () => {
    if (auth.subscription) {
      setLoading(true);

      try {
        await api.uploadCustomCssToProjectForUser(
          project?.slug ?? "",
          customCss
        );
        setShowSuccessNotif(true);
      } catch (error) {
        console.error("Error occurred while trying to update CSS -", error);
        setShowErrorNotif(true);
      }
      setLoading(false);
    }
  };

  const updateHeadJs = async () => {
    if (auth.subscription) {
      setLoading(true);

      try {
        await api.uploadCustomHeadJsToProjectForUser(
          project?.slug ?? "",
          customHeadJs
        );
        setShowSuccessNotif(true);
      } catch (error) {
        console.error("Error occurred while trying to update head JS -", error);
        setShowErrorNotif(true);
      }
      setLoading(false);
    }
  };

  const updateBodyJs = async () => {
    if (auth.subscription) {
      setLoading(true);

      try {
        await api.uploadCustomBodyJsToProjectForUser(
          project?.slug ?? "",
          customBodyJs
        );
        setShowSuccessNotif(true);
      } catch (error) {
        console.error("Error occurred while trying to update body JS -", error);
        setShowErrorNotif(true);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <div className="min-h-full">
        <NavBar pageName={project?.name ? project.name : "Manage Project"} />
        <main className="-mt-32">
          <div className="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
            {/* Replace with your content */}
            <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
              {loadingProject ? (
                <>
                  <div className="font-bold">Loading...</div>
                  <div className="text-sm w-1/2 pt-2 text-gray-500">
                    If this page has been loading for a while, check for any
                    Adblockers/extensions installed that could block essential
                    scripts from running
                  </div>
                </>
              ) : project ? (
                <>
                  <main className="max-w-lg pb-12 lg:pb-16">
                    <div className="space-y-6">
                      <div>
                        <h1 className="text-lg leading-6 font-medium text-gray-900">
                          Project Settings
                        </h1>
                        <p className="mt-1 text-sm text-gray-500">
                          Live at{" "}
                          <a
                            className="text-indigo-500 hover:text-indigo-700"
                            target={"_blank"}
                            rel="nofollow"
                            href={
                              "https://" +
                                project.publishedUrl
                                  ?.replace("https://", "")
                                  .replace("http://", "") ?? ""
                            }
                          >
                            {project.publishedUrl}
                          </a>
                        </p>
                      </div>

                      <div>
                        <label
                          htmlFor="project-slug"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Project Slug
                        </label>
                        <div className="mt-1">
                          <input
                            disabled
                            type="text"
                            name="project-slug"
                            id="project-slug"
                            className="block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            defaultValue={project.slug}
                          />
                        </div>
                      </div>

                      <div>
                        <label
                          htmlFor="project-directory"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Project Directory (in Obsidian)
                        </label>
                        <div className="mt-1">
                          <input
                            disabled
                            type="text"
                            name="project-directory"
                            id="project-directory"
                            className="block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            defaultValue={project.folderToShare}
                          />
                        </div>
                      </div>

                      <div>
                        <div>
                          <label
                            htmlFor="custom-domain"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Custom Domain
                          </label>
                          <div className="mt-2 flex rounded-md shadow-sm">
                            <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                              https://
                            </span>
                            <input
                              type="text"
                              disabled={loading || !auth.subscription}
                              name="custom-domain"
                              id="custom-domain"
                              className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 disabled:bg-gray-100"
                              placeholder="example.com"
                              value={customDomain.replace("https://", "")}
                              onChange={(e) => setCustomDomain(e.target.value)}
                            />
                            <button
                              type="button"
                              disabled={loading || !auth.subscription}
                              onClick={updateCustomDomain}
                              className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 disabled:bg-gray-200"
                            >
                              <span>Save</span>
                            </button>
                          </div>
                        </div>
                        <div className="my-4 text-sm">
                          {auth.subscription ? (
                            <>
                              <div className="">
                                After setting your custom domain, add the
                                following DNS records to your domain registrar
                              </div>
                              <table className="min-w-full divide-y divide-gray-300 my-4 rounded-md">
                                <thead className="bg-gray-50">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                    >
                                      Type
                                    </th>
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                                    >
                                      Name
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                    >
                                      Value
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                  {!customDomain.startsWith("www.") ? (
                                    <tr>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        A
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        @
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        76.76.21.21
                                      </td>
                                    </tr>
                                  ) : (
                                    <>
                                      <tr>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                          A
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                          @
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                          76.76.21.21
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                          CNAME
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                          www
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                          cname.vercel-dns.com
                                        </td>
                                      </tr>
                                    </>
                                  )}
                                </tbody>
                              </table>
                            </>
                          ) : (
                            <>
                              <div className="">
                                To add a custom domain, you must be{" "}
                                <Link to={"/choose-subscription"}>
                                  <span className="text-indigo-500 hover:text-indigo-800 cursor-pointer">
                                    {" "}
                                    subscribed to a paid plan
                                  </span>
                                </Link>
                              </div>
                            </>
                          )}
                        </div>
                      </div>

                      <div className="flex items-start space-x-4">
                        <div className="min-w-0 flex-1">
                          <div className="relative">
                            <label
                              htmlFor="customCss"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Custom CSS
                            </label>
                            {!auth.subscription && (
                              <div className="my-4 text-sm">
                                To add custom CSS, you must be{" "}
                                <Link to={"/choose-subscription"}>
                                  <span className="text-indigo-500 hover:text-indigo-800 cursor-pointer">
                                    {" "}
                                    subscribed to a paid plan
                                  </span>
                                </Link>
                              </div>
                            )}
                            <div className="mt-1 border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
                              <textarea
                                disabled={loading || !auth.subscription}
                                rows={3}
                                name="customCss"
                                id="customCss"
                                className="block w-full py-3 border-0 resize-none focus:ring-0 sm:text-sm disabled:bg-gray-100"
                                placeholder="Add your CSS here..."
                                value={customCss}
                                onChange={(e) => {
                                  setCustomCss(e.target.value);
                                }}
                              />

                              {/* Spacer element to match the height of the toolbar */}
                              <div className="py-2" aria-hidden="true">
                                {/* Matches height of button in toolbar (1px border + 36px content height) */}
                                <div className="py-px">
                                  <div className="h-9" />
                                </div>
                              </div>
                            </div>

                            <div className="absolute bottom-0 inset-x-0 pl-3 pr-2 py-2 flex justify-between">
                              <div className="flex-shrink-0">
                                <button
                                  onClick={updateCss}
                                  disabled={loading || !auth.subscription}
                                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-gray-200"
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex items-start space-x-4">
                        <div className="min-w-0 flex-1">
                          <div className="relative">
                            <label
                              htmlFor="customHeadJs"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Custom Javascript (head)
                            </label>
                            {!auth.subscription && (
                              <div className="my-4 text-sm">
                                To add custom Javascript, you must be{" "}
                                <Link to={"/choose-subscription"}>
                                  <span className="text-indigo-500 hover:text-indigo-800 cursor-pointer">
                                    {" "}
                                    subscribed to a paid plan
                                  </span>
                                </Link>
                              </div>
                            )}
                            <div className="mt-1 border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
                              <textarea
                                disabled={loading || !auth.subscription}
                                rows={3}
                                name="customHeadJs"
                                id="customHeadJs"
                                className="block w-full py-3 border-0 resize-none focus:ring-0 sm:text-sm disabled:bg-gray-100"
                                placeholder="Add your Javascript here..."
                                value={customHeadJs}
                                onChange={(e) => {
                                  setCustomHeadJs(e.target.value);
                                }}
                              />

                              {/* Spacer element to match the height of the toolbar */}
                              <div className="py-2" aria-hidden="true">
                                {/* Matches height of button in toolbar (1px border + 36px content height) */}
                                <div className="py-px">
                                  <div className="h-9" />
                                </div>
                              </div>
                            </div>

                            <div className="absolute bottom-0 inset-x-0 pl-3 pr-2 py-2 flex justify-between">
                              <div className="flex-shrink-0">
                                <button
                                  onClick={updateHeadJs}
                                  disabled={loading || !auth.subscription}
                                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-gray-200"
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex items-start space-x-4">
                        <div className="min-w-0 flex-1">
                          <div className="relative">
                            <label
                              htmlFor="customBodyJs"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Custom Javascript (end of body)
                            </label>
                            {!auth.subscription && (
                              <div className="my-4 text-sm">
                                To add custom Javascript, you must be{" "}
                                <Link to={"/choose-subscription"}>
                                  <span className="text-indigo-500 hover:text-indigo-800 cursor-pointer">
                                    {" "}
                                    subscribed to a paid plan
                                  </span>
                                </Link>
                              </div>
                            )}
                            <div className="mt-1 border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
                              <textarea
                                disabled={loading || !auth.subscription}
                                rows={3}
                                name="customBodyJs"
                                id="customBodyJs"
                                className="block w-full py-3 border-0 resize-none focus:ring-0 sm:text-sm disabled:bg-gray-100"
                                placeholder="Add your Javascript here..."
                                value={customBodyJs}
                                onChange={(e) => {
                                  setCustomBodyJs(e.target.value);
                                }}
                              />

                              {/* Spacer element to match the height of the toolbar */}
                              <div className="py-2" aria-hidden="true">
                                {/* Matches height of button in toolbar (1px border + 36px content height) */}
                                <div className="py-px">
                                  <div className="h-9" />
                                </div>
                              </div>
                            </div>

                            <div className="absolute bottom-0 inset-x-0 pl-3 pr-2 py-2 flex justify-between">
                              <div className="flex-shrink-0">
                                <button
                                  disabled={loading || !auth.subscription}
                                  onClick={updateBodyJs}
                                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-gray-200"
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <label
                          htmlFor="delete-project"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Delete Project
                        </label>
                        <button
                          disabled={loading}
                          type="button"
                          className="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                          onClick={() => setOpenDeleteProjectDialog(true)}
                        >
                          Delete
                        </button>
                      </div>
                      <DeleteModal
                        open={openDeleteProjectDialog}
                        setOpen={setOpenDeleteProjectDialog}
                        action={() => deleteProject()}
                        title={"Are you sure you want to delete this project?"}
                        description={"It cannot be recovered once deleted"}
                      />

                      <div>
                        <p className="mt-1 text-sm text-gray-500 italic">
                          Last updated on{" "}
                          <span className="font-semibold">
                            {new Date(
                              project.metadata?.updatedAt ?? ""
                            ).toLocaleString()}
                          </span>
                        </p>
                      </div>
                    </div>
                  </main>
                  {/* <div className="my-8 flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                >
                                  Name
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                                >
                                  URL
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Last Updated
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Visibility
                                </th>
                                <th
                                  scope="col"
                                  className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                                >
                                  <span className="sr-only">Edit</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                              {userProjects.map((project, i) => (
                                <tr key={i}>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    {project.name}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {project.publishedUrl}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {new Date(
                                      project.metadata.updatedAt
                                    ).toDateString()}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {project.public ? (
                                      <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                        Public
                                      </span>
                                    ) : (
                                      <span className="inline-flex rounded-full bg-indigo-100 px-2 text-xs font-semibold leading-5 text-indigo-800">
                                        Private
                                      </span>
                                    )}
                                  </td>
                                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                    <button
                                      onClick={() =>
                                        setOpenDeleteProjectDialog(true)
                                      }
                                      className="cursor-pointer text-red-600 hover:text-red-900"
                                    >
                                      Delete
                                      <span className="sr-only">
                                        , {project.name}
                                      </span>
                                    </button>
                                  </td>
                                  <DeleteModal
                                    open={openDeleteProjectDialog}
                                    setOpen={setOpenDeleteProjectDialog}
                                    action={() => deleteProject(project.id)}
                                    title={
                                      "Are you sure you want to delete this project?"
                                    }
                                    description={
                                      "It cannot be recovered once deleted"
                                    }
                                  />
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h2 className="text-2xl font-bold text-black">Your Token</h2>
                  <div className="mt-2">
                    Take your unique user token below and copy it into the
                    Markbase for Obsidian plugin settings section
                  </div>
                  <div className="mt-4 flex flex-col">
                    <CopyField
                      value={obsidianUserToken}
                      leadingIcon={
                        <KeyIcon className="h-5 w-5 text-gray-400" />
                      }
                    />
                  </div> */}
                </>
              ) : (
                <>
                  <Navigate to={"/dashboard"} />
                </>
              )}
            </div>
            {/* /End replace */}
          </div>
        </main>
      </div>
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={showSuccessNotif}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully saved!
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => {
                        setShowSuccessNotif(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={showErrorNotif}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <XIcon
                      className="h-6 w-6 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      An error occurred - please try again or contact support
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => {
                        setShowErrorNotif(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
