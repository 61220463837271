import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MarkbaseFavicon from "../../assets/img/markbase-favicon.png";
import { useAuth } from "../../utils/auth/auth";

export default function SignOut() {
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    auth.signout(() => {
      navigate("/signin");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src={MarkbaseFavicon}
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Signing out of Markbase...
          </h2>
        </div>
      </div>
    </>
  );
}
